import { v4 as uuidv4 } from 'uuid';

export const uuid = (): string => {
  return uuidv4();
};

export const stringToColor = (str: string) => {
  const hash = Array.from(str).reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  const h = ((hash % 360) % 300) + 20;
  const s = 85 + (hash % 10) + '%';
  const l = 33 + (hash % 5) + '%';
  return `hsl(${h}, ${s}, ${l})`;
};

export function throttle<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let lastArgs: Parameters<T> | undefined;

  return function throttled(this: D, ...args: Parameters<T>): void {
    if (timeoutId === undefined) {
      func.apply(this, args);
      timeoutId = setTimeout(() => {
        timeoutId = undefined;
        if (lastArgs !== undefined) {
          throttled.apply(this, lastArgs);
          lastArgs = undefined;
        }
      }, wait);
    } else {
      lastArgs = args;
    }
  };
}

export function debounce<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function debounced(this: D, ...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export const capitalizeFirstLetter = (str: string): string => str.replace(/^./, (match) => match.toUpperCase());

export const regexps = {
  name: /^[a-zA-Z\s]{2,150}$/,
  ssn: /^\d{3}-{0,1}\d{2}-{0,1}\d{4}$/,
  date: /^\d{4}-\d{2}-\d{2}$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  phone: /^\+1\s*(?:\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$|^\d{10}$|^\d{3}[\s-]\d{3}[\s-]\d{4}$|^\(\d{3}\)\s?\d{3}[\s-]\d{4}$/,
};
